import React from 'react';
import '../css/footer.css';
import { Link } from 'react-router-dom';

function Footer() {  
  
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>About Us</h3>
          <p>We are dedicated to providing personalized property services for vendors, agents, landlords and tenants alike.</p>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/home">Home</Link></li>
            <li><Link to="/selling">Selling</Link></li>
            <li><Link to="/agents">Agents</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
                     
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>Email: info@acresulis.co.uk</p>
          <p>Phone: +44 7507 494 884</p>          
        </div>        
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Acre Sulis Ltd. All rights reserved.</p>
        <p>Acre Sulis ltd is a company registered in England and Wales (Company Number 15946730)</p>
      </div>
    </footer>
  );
}

export default Footer;